<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  created(){
    //英
    localStorage.setItem('type', 1)
    localStorage.setItem("language", "en_US");
    //日
    // localStorage.setItem('type', 2)
    // localStorage.setItem("language", "ja_CN");
    this.$i18n.locale = localStorage.getItem("language");
  },
  mounted(){
    // console.log(document.documentElement.clientWidth);
  },
  methods:{
    
  }
}
</script>

<style>
/* 英 */
@font-face{
  font-family: font-name;
  src: url('@/assets/Montserrat-VariableFont_wght.ttf')
}
/* 日 */
/* @font-face{ 
  font-family: font-name;
  src: url('@/assets/NotoSansJP-VariableFont_wght.ttf')
} */
@font-face{
  font-family: font-name1;
  src: url('@/assets/Montserrat-VariableFont_wght.ttf')
}
@font-face{
  font-family: font-name2;
  src: url('@/assets/NotoSansJP-VariableFont_wght.ttf')
}
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}
</style>
